<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Editar Póliza')}} </h3>
		</div>
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="poliza" :scrollable="true" scrollHeight="400px" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                    <template #header>
                        <div class="table-header">
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="buscar" type="number"/>
                            </span>
                            <Button :label="$t('Buscar')" icon="pi pi-search" class="p-button-secondary" @click="Buscar"/>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.estado==0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Anulada')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==1">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Inicial')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==2">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Evaluación')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==3">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Compromiso')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==4">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Verificación  de Pago')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==6">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Anulada')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==7">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Proceso Renovación')}}
                            </div>
                            <div v-else-if="slotProps.data.vend<0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Vencida')}}
                            </div>
                            <div v-else-if="slotProps.data.pendiente>0">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Pago Pendiente')}}
                            </div>
                            <div v-else-if="slotProps.data.proceso>0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Suspendida')}} <br> ({{$t('Pago Vencido')}})
                            </div>
                            <div v-else>
                                <Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff"/>
                                {{$t('Activa')}}
                            </div>
                        </template>
                    </Column>                       
                    <Column field="codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                              
                        </template>
                    </Column>
                    <Column field="duracion_name" :header="$t('Duración')" :sortable="true" headerStyle="width: 110px">
                        <template #body="slotProps">
                            <div v-if="i18n.locale() == 'es'">{{slotProps.data.duracion_nombre}}</div>
                            <div v-else>{{slotProps.data.duracion_name}}</div>     
                        </template>
                    </Column>
                    <Column field="activacion" :header="$t('Activación')" :sortable="true" headerStyle="width: 110px">
                        <template #body="slotProps">
                            {{slotProps.data.activacion}}
                        </template>
                    </Column>
                    <Column field="vence" :header="$t('Vencimiento')" :sortable="true" headerStyle="width: 110px">
                        <template #body="slotProps">
                            {{slotProps.data.vence}}
                        </template>
                    </Column>
                    <Column :header="$t('Acciones')" headerStyle="width: 120px">
                        <template #body="slotProps">
                            <Button :label="$t('Fechas')" icon="pi pi-calendar" class="p-button-info p-mb-2 p-mr-2"  @click="Editar(slotProps.data)" style="width: 100%;" v-if="slotProps.data.estado==5"/>
                            <Button :label="$t('Licencias')" icon="pi pi-users" class="p-button-warning p-mb-2 p-mr-2"  @click="Licencia(slotProps.data)" style="width: 100%;"/>
                            <Button :label="$t('Keuringskaart')" icon="pi pi-id-card" class="p-button-secondary p-mb-2 p-mr-2"  @click="Certif(slotProps.data)" style="width: 100%;"/>
                            <Button :label="$t('Reversar')" icon="pi pi-undo" class="p-button-danger p-mb-2 p-mr-2"  @click="Reversar(slotProps.data)" 
                                v-if="(slotProps.data.estado==4 || slotProps.data.estado==5) && !slotProps.data.cambio"  style="width: 100%;"/>
                            <div v-if="slotProps.data.migracion">{{$t('Migración')}}</div>
                            <div v-if="slotProps.data.cambio">{{$t('Cambio de Vehículo')}}</div>
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>

        <Dialog v-model:visible="displayeditar" :style="{width: size}" :header="$t('Fechas')" :modal="true" :maximizable="true">
            <br>
            <div class="confirmation-content">
                <div class="p-field">
                    <div class="p-grid">
                        <div class="p-col-4">
                            {{$t('Póliza')+' #'}}: <strong>{{police.codigo}}</strong>			
                        </div>
                        <div class="p-col-4">
                            {{$t('Activación')}}: 	
                            <InputMask v-model="police.activacion" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                        </div>
                        <div class="p-col-4">
                            {{$t('Vencimiento')}}: 
                            <InputMask v-model="police.vence" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <template #footer>
                <Button :label="$t('Guardar')" icon="pi pi-save" class="p-button-secondary" @click="GuardarFechas" />
                <Button :label="$t('Cerrar')" icon="pi pi-times" class="p-button-danger" @click="displayeditar = false"/>
            </template>
        </Dialog>
        <Dialog v-model:visible="displaycertif" :style="{width: size}" :header="$t('Keuringskaart')" :modal="true" :maximizable="true">
            <br>
            <div class="confirmation-content">
                <div class="p-field">
                    <div class="p-grid">
                        <div class="p-col-6">
                            <label>{{$t('Fecha de emisión')}}</label>
                            <InputMask v-model="police.vehiculo.cardemi" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                        </div>
                        <div class="p-col-6">
                            {{$t('Registro')}}: <strong>{{police.vehiculo.cardreg}}</strong>
                        <label>{{$t('Validez')}}</label>
                            <Dropdown v-model="police.vehiculo.cardval" :options="validez" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                        </div>
                        <div class="p-col-6">
                            <FileUpload :chooseLabel="$t('Subir')" uploadLabel="Cargar" mode="basic" :name="img" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader()" :auto="true"/>
                        </div>
                        <div class="p-col-6">
                            <img style="width: 100px;" :src="url.upload+police.vehiculo.veh_adverso" @click="toggle" v-if="police.vehiculo.veh_adverso"/>
                            <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
                                <img style="width: 700px;" :src="url.upload+police.vehiculo.veh_adverso"/>
                            </OverlayPanel>
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <template #footer>
                <Button :label="$t('Guardar')" icon="pi pi-save" class="p-button-secondary" @click="GuardarCertif" />
                <Button :label="$t('Cerrar')" icon="pi pi-times" class="p-button-danger" @click="displaycertif = false"/>
            </template>
        </Dialog>

        <Dialog v-model:visible="displaylic" :style="{width: size}" :header="$t('Licencias')" :modal="true" :maximizable="true">
            <br>
            <div class="confirmation-content">
                <div class="p-field">
                    <div class="p-grid">
                        <div class="p-col-4 p-md-2" v-if="!LicenciaDialog">
                            <Button :label="$t('Agregar Licencia')" icon="pi pi-plus-circle" class="p-button-secondary" @click="Agregar()" />
                        </div>              
                        <div class="p-col-12">                    
                            <DataTable :value="police.licencias" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
								:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                                <Column :header="$t('Licencia')">
                                    <template #body="slotProps">
                                        {{slotProps.data.categoria}}
                                    </template>
                                </Column>
                                <Column :header="$t('Número ID')">
                                    <template #body="slotProps">
                                        {{slotProps.data.code}}
                                        <Avatar icon="bi bi-asterisk" class="p-mr-2" style="background-color:blue; color: #ffffff" v-if="slotProps.data.habitual"/>
                                    </template>
                                </Column>
                                <Column :header="$t('Nombre Completo')">
                                    <template #body="slotProps">
                                        {{slotProps.data.nombre}}
                                    </template>
                                </Column>
                                <Column :header="$t('Fecha de emisión')">
                                    <template #body="slotProps">
                                        {{convfech(slotProps.data.emision)}}
                                    </template>
                                </Column>
                                <Column :header="$t('Fecha de caducidad')">
                                    <template #body="slotProps">
                                        {{convfech(slotProps.data.caducidad)}}
                                    </template>
                                </Column>
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="DeleteLicencia(slotProps.data)"/>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <template #footer>
                <Button :label="$t('Guardar')" icon="pi pi-save" class="p-button-secondary" @click="GuardarLic" />
                <Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="displaylic = false"/>             
            </template>
        </Dialog>

        <Dialog v-model:visible="LicenciaDialog" :style="{width: size}" :header="$t('Agregar Licencia')" :modal="true" :maximizable="true"> 
            <br>
            <div class="confirmation-content">
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-6">
						<label for="name">{{$t('Permiso de Conducir')}}</label>
						<MultiSelect v-model="licencia.categoria" :options="categorias" optionLabel="nombre" placeholder="...?" :filter="true" class="multiselect-custom">
							<template #value="slotProps">
								<div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.code">
									<img style="width: 20px;" :alt="slotProps.value.id" :src="'images/' + option.code + '.png'"/>
									<div>{{option.nombre}}</div>
								</div>
								<template v-if="!slotProps.value || slotProps.value.length === 0">
									{{$t('Licencia')}}
								</template>
							</template>
							<template #option="slotProps">
								<div class="country-item">
									<img style="width: 50px;" :alt="slotProps.option.id" :src="'images/' + slotProps.option.code + '.png'" />
									<div>{{slotProps.option.nombre}}</div>
								</div>
							</template>
						</MultiSelect>
					</div>
					<div class="p-field p-col-6">
						<label>{{$t('Número ID')}}</label>
						<InputText v-model="licencia.code" type="text"/>
					</div>
					<div class="p-field p-col-12">
						<label>{{$t('Nombre Completo')}}</label>
						<InputText v-model="licencia.nombre" type="text"/>
					</div>
					<div class="p-field p-col-4">
						<label>{{$t('País de emisión')}}</label>
						<Dropdown v-model="licencia.pais" :options="paises" :optionLabel="$t('nombre')" :filter="true" :placeholder="$t('Seleccione...')">
							<template #value="slotProps">
								<div class="p-dropdown-car-value" v-if="slotProps.value">
									<span v-if="i18n.locale() == 'es'"> {{slotProps.value.nombre}}</span>
									<span v-if="i18n.locale() == 'en'"> {{slotProps.value.name}}</span>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
							<template #option="slotProps">
								<div class="p-dropdown-car-option">
									<span v-if="i18n.locale() == 'es'"> {{slotProps.option.nombre}}</span>
									<span v-if="i18n.locale() == 'en'"> {{slotProps.option.name}}</span>
								</div>
							</template>
						</Dropdown>
					</div>
                    <div class="p-field p-col-4">
                    <label>{{$t('Fecha de emisión')}}</label>
                        <InputMask v-model="licencia.emision" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                    </div>
                    <div class="p-field p-col-4">
                        <label>{{$t('Fecha de caducidad')}}</label>
                        <InputMask v-model="licencia.caducidad" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                    </div>
					<div class="p-field p-col-6">
						<label>{{$t('Conductor habitual')}}</label>
                        <Checkbox v-model="licencia.habitual" :binary="true"/>
					</div>
					<div class="p-field p-col-6">
						<label>{{$t('Anverso')}}</label>
						<FileUpload :chooseLabel="$t('Subir Foto')+ ' JPG,JPEG'" uploadLabel="Cargar" mode="basic" :name="licencia.adverso" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploaderlic(1,licencia.adverso)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
						<img style="width: 60px;" :src="url.upload+licencia.adverso" v-if="licencia.verif"/>
					</div>
					<div class="p-field p-col--12">
						<label for="state">{{$t('¿Le han revocado la licencia?')}}</label>
                        <Checkbox v-model="licencia.revocado" :binary="true" />
					</div>
                    <div class="p-field p-col-12" v-if="LicenciaDialog && licencia.revocado">
                    <label>{{$t('indique los detalles')}}</label>
						<InputText v-model="licencia.detalle"  type="text"/>
                    </div>                </div>
            </div>
            <template #footer>
                <Button :label="$t('Agregar Licencia')" icon="pi pi-plus-circle" class="p-button-secondary" @click="AgregarLicencia" />
                <Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="LicenciaDialog = false"/>
            </template>
        </Dialog>


        <Dialog v-model:visible="displayreg" :style="{width: size}" :header="$t('Reversar')" :modal="true" :maximizable="true">
            <br>
            <div class="confirmation-content">
                <div class="p-field">
                    <h4>{{$t('Confirmar_Reverso')}}</h4>
                    
                </div>
                <br>
            </div>
            <template #footer>
                <Button :label="$t('Sí')" icon="pi pi-check" class="p-button-secondary" @click="ProcesarRev" />
                <Button :label="$t('No')" icon="pi pi-times" class="p-button-danger" @click="displayreg = false"/>

                

            </template>
        </Dialog>


        <View :display="displayview" :police="police" :duracion="duracion" :cuotas="cuotas" @aprob-view="onMenuToggle"  @close-view="close"/>
    </div>
</template>

<script>
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import View from '../components/View.vue';
import Token from "uuid-token-generator";


export default {
	components: {
        'View': View
    },
	data() {
		return {
			selectedCustomers: null,
			filters: {},
			i18n: null,
            caso: null,
            poliza: [],
			url: null,
            buscar: null,
            polizas: [],
            displayview: false,
            police: {},
            duracion: [],
            estad: [],
            estados: [],
            cuotas: [],
            displayeditar: false,
            displaylic: false,
            displaycertif: false,
            displayreg: false,
            LicenciaDialog: false,
			categorias: [
                {nombre: 'A', code: 'A'},
                {nombre: 'B', code: 'B'},
                {nombre: 'C', code: 'C'},
                {nombre: 'D', code: 'D'},
                {nombre: 'E', code: 'E'},
			],
			licencia: {
                id: 0,
                poliza:0,
				code: null,
				nombre: null,
				categoria: null,
				emision: null,
				caducidad: null,
				pais: null,
				habitual: true,
				revocado: false,
				detalle: null,				
				adverso: null,			
				reverso: null,				
				verif: false				
			},
            paises: [],
			validez: [],
			pdf: null,
			img: null,
            size: '60vw',
            fecha: null,
            recargo: 0,
        }
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
		const tokgen = new Token(256);
		this.pdf = tokgen.generate();
		this.img = tokgen.generate();
	},
	mounted() {
	},
	methods: {
        Buscar(){
			this.$store.commit('Loading');
			this.poliza = [];
            const Consulta = new API('Configuracion');
			Consulta.Procesar('EditPoliza',{
				caso: 	'Buscar',
				id: this.buscar,
				}).then(response => {
					//this.$store.state.error = response.result.poliza;	
                    if (response.result.poliza){
                        this.validez = response.result.validez;
                        this.poliza = response.result.poliza;
                        //this.poliza[0].vence = Consulta.fdate(this.poliza.vence);
                        //this.poliza[0].vehiculo.cardemi = Consulta.fdate(this.poliza[0].vehiculo.cardemi);
                        this.paises = response.result.paises;
                    }
                    this.$store.commit('Loading');
                });
        },
        Editar(data) {
			this.$store.commit('Loading');
            this.police = {...data};
            this.$store.commit('Loading');
            this.displayeditar = true;
        },
        GuardarFechas() {
			this.$store.commit('Loading');
            this.$store.commit('Mobile');
            const Consulta = new API('Configuracion');
            var activacion = Consulta.fdate(this.police.activacion);
            var vence = Consulta.fdate(this.police.vence);
			Consulta.Procesar('EditPoliza',{
				caso: 	'Fechas',
                poliza: this.police.id,
                activacion: activacion,
                vence: vence,
				}).then(response => {
					//this.$store.state.error =  response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
                        this.displayeditar = false;
						this.$store.commit('Loading');
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
                        this.displayeditar = false;
						this.$store.commit('Loading');
					}
                });           
        },
        GuardarCertif() {
			this.$store.commit('Loading');
            this.$store.commit('Mobile');
            const Consulta = new API('Configuracion');
            var fecha = Consulta.fdate(this.police.vehiculo.cardemi);
			Consulta.Procesar('EditPoliza',{
				caso: 	'Certif',
                vehiculo: this.police.vehiculo,
                fecha: fecha,
				}).then(response => {
					//this.$store.state.error =  response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
                        this.displaycertif = false;
						this.$store.commit('Loading');
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
                        this.displaycertif = false;
						this.$store.commit('Loading');
					}
                });           
        },
        Licencia(data) {
			this.$store.commit('Loading');
            this.police = {...data};
            this.$store.commit('Loading');
            this.displaylic = true;
        },
        Certif(data) {
			this.$store.commit('Loading');
            this.police = {...data};
            if(this.police.vehiculo.cardemi){
                this.police.vehiculo.cardemi = this.convfech(this.police.vehiculo.cardemi);
            }
            if(this.police.vehiculo.cardval){
                this.police.vehiculo.cardval = this.validez.filter(val => val.code == this.police.vehiculo.cardval)[0];
            }
            this.$store.commit('Loading');
            this.displaycertif = true;
        },
        Reversar(data) {
			this.$store.commit('Loading');
            this.police = {...data};
            this.$store.commit('Loading');
            this.displayreg = true;
        },
        Agregar() {
			this.LicenciaDialog = true;
            this.licencia.id = 0;
            this.licencia.poliza = this.police.id;
			const tokgen = new Token(256);
			this.licencia.adverso = tokgen.generate()+'1';
			this.licencia.reverso = tokgen.generate()+'2';
		},
		myUploaderlic(caso,img) {
			this.$store.commit('Loading');
			const Consulta = new API('Configuracion');
			Consulta.Procesar('EditPoliza',{
				caso: 	'RevFoto',
				foto: img,
			}).then(response => {
                //this.$store.state.error = response;	
				if(response.result){
                    this.licencia.verif = true;
					if(caso==1){this.licencia.adverso = response.result;} 
					if(caso==2){this.licencia.reverso = response.result;} 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
				this.$store.commit('Loading');
			});
		},
		myUploader() {
			this.$store.commit('Loading');
			const Consulta = new API('Configuracion');
			Consulta.Procesar('EditPoliza',{
				caso: 	'RevFoto',
				foto: this.img,
			}).then(response => {
				if(response.result){
					this.police.vehiculo.veh_adverso = response.result;
				} else {
					this.foto = response.result;
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
                const tokgen = new Token(256);
                this.img = tokgen.generate();
				this.$store.commit('Loading');
			});
		},
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		DeleteLicencia(selec) {
			this.police.licencias = this.police.licencias.filter(val => val !== selec);
		},
		AgregarLicencia() {
            if (this.licencia.verif) {
				this.$store.commit('Loading');
                this.LicenciaDialog = false;
				var precat = null;				
				this.licencia.categoria.forEach(element => {
					if (precat == null){
						precat = element.code;
					} else {
						precat = precat+', '+element.code	;
					}
				});
				this.police.licencias.push({
                    id: 0,
                    poliza: this.licencia.poliza,
                    code: this.licencia.code,
                    nombre: this.licencia.nombre,
                    categoria: precat,
                    emision: this.fdate(this.licencia.emision),
                    caducidad: this.fdate(this.licencia.caducidad),
                    pais: this.licencia.pais.code,
                    habitual: this.licencia.habitual,
                    revocado: this.licencia.revocado,
                    detalle: this.licencia.detalle,				
                    adverso: this.licencia.adverso,			
                    
                });
				this.licencia = {
					id: 0,
                    poliza:0,
                    code: null,
                    nombre: null,
                    categoria: null,
                    emision: null,
                    caducidad: null,
                    pais: null,
                    habitual: true,
                    revocado: false,
                    detalle: null,				
                    adverso: null,			
                    reverso: null,				
                    verif: false			
				};
				this.$store.commit('Loading');
            }
		},
        GuardarLic(){
			this.$store.commit('Loading');
            this.$store.commit('Mobile');
            const Consulta = new API('Configuracion');
			Consulta.Procesar('EditPoliza',{
				caso: 	'GuardarLic',
                poliza: this.police,
				}).then(response => {
					//this.$store.state.error =  response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
                        this.displaylic = false;
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
					}
                    this.$store.commit('Loading');
                });           
        },
        ProcesarRev(){
			this.$store.commit('Loading');
            this.$store.commit('Mobile');
            const Consulta = new API('Configuracion');
			Consulta.Procesar('EditPoliza',{
				caso: 	'ProcesarRev',
                poliza: this.police,
				}).then(response => {
					//this.$store.state.error =  response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
                        this.displayreg = false;
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
					}
                    this.$store.commit('Loading');
                });           
        },
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
        fdate(fecha){
            const [dia, mes, ano] = fecha.split("/");
            let cambiada = `${ano}-${mes}-${dia}`;
            return cambiada;
        },
        toggle(event) {
			this.$refs.op.toggle(event);
		},

	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
