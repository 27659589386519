<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Pólizas de Broker')}} </h3>
		</div>
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="poliza" :scrollable="true" scrollHeight="400px" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                    <template #header>
                        <div class="table-header">
                            <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar()"></Dropdown>
                            <Dropdown v-model="opcion2" :options="opciones2" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar2()" v-if="opcion"></Dropdown>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.estado==4">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Verificación  de Pago')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==6">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Cancelada')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==7">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Proceso Renovación')}}
                            </div>
                            <div v-else-if="slotProps.data.vend<0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Vencida')}}
                            </div>
                            <div v-else-if="slotProps.data.pendiente>0">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Pago Pendiente')}}
                            </div>
                            <div v-else-if="slotProps.data.proceso>0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Suspendida')}} <br> ({{$t('Pago Vencido')}})
                            </div>
                            <div v-else>
                                <Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff"/>
                                {{$t('Activa')}}
                            </div>
                        </template>
                    </Column>                     
                    <Column field="codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                              
                        </template>
                    </Column>
                    <Column field="duracion_name" :header="$t('Duración')" :sortable="true" headerStyle="width: 110px">
                        <template #body="slotProps">
                            <div v-if="i18n.locale() == 'es'">{{slotProps.data.duracion_nombre}}</div>
                            <div v-else>{{slotProps.data.duracion_name}}</div>     
                        </template>
                    </Column>
                    <Column field="activacion" :header="$t('Activación')" :sortable="true" headerStyle="width: 110px">
                        <template #body="slotProps">
                            {{slotProps.data.activacion}}
                        </template>
                    </Column>
                    <Column field="vence" :header="$t('Vencimiento')" :sortable="true" headerStyle="width: 110px">
                        <template #body="slotProps">
                            {{slotProps.data.vence}}
                        </template>
                    </Column>
                    <Column :header="$t('Acciones')" headerStyle="width: 165px">
                        <template #body="slotProps">
                            <Button icon="pi pi-eye" class="p-button-rounded p-button-secondary p-mr-2" @click="openview(slotProps.data)"/>
                            <Button icon="pi pi-refresh" class="p-button-rounded p-button-danger p-mr-2" @click="Renovacion(slotProps.data)" v-if="slotProps.data.vend<=3  && slotProps.data.deudas==0 && slotProps.data.estado==5 && slotProps.data.vend>=(-30)"/>
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-info p-mr-2" @click="Editar(slotProps.data)"  v-if="slotProps.data.estado==5 && slotProps.data.vend>=0 && slotProps.data.pendiente<=0 && slotProps.data.proceso==0"/>
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(slotProps.data)" v-if="slotProps.data.estado==5 && slotProps.data.vend>=0 && slotProps.data.pendiente<=0 && slotProps.data.proceso==0"/>
                        </template>
                    </Column>
                    <Column :header="$t('Anexos')" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="VerPDF(slotProps.data.firmado)" v-if="slotProps.data.firmado"/>
							<FileUpload :chooseLabel="$t('PDF')" uploadLabel="Cargar" mode="basic" :name="pdf" :url="url.api+'uploadpdf.php'"  accept="pdf" :maxFileSize="4000000" @upload="myUploader(slotProps.data)" :auto="true"  v-if="slotProps.data.estado==5 && slotProps.data.vend>=0 && slotProps.data.pendiente<=0 && slotProps.data.proceso==0"/>
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>

        <Dialog v-model:visible="displayeditar" :style="{width: size}" :header="$t('Póliza')" :modal="true" :maximizable="true">
            <br>
            <div class="confirmation-content">
                <div class="p-field">
                    <h4>{{$t('Información Básica')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-12">
                            {{$t('Póliza')+' #'}}: <strong>{{police.poliz.codigo}}</strong>	
                        </div>
                        <div class="p-col-4">
                            <div class="p-grid">
                                <div class="p-col-12">
                                    {{$t('Activación')}}: <strong>{{police.fecha}}</strong>			
                                </div>
                                <div class="p-col-12">
                                    {{$t('Vencimiento')}}: <strong>{{police.vence2}}</strong>			
                                </div>
                            </div>
                        </div>
                        <div class="p-col-4">
                            <div class="p-grid">
                                <div class="p-col-6">
                                    {{$t('Nº Días')}}:
                                </div>	
                                <div class="p-col-6">
                                    <strong>{{police.poliz.dias}}</strong>
                                </div>	
                                <div class="p-col-6">
                                    {{$t('Nº Días Pendientes')}}:
                                </div>	
                                <div class="p-col-6">
                                    <strong>{{parseFloat(police.poliz.vend)}}</strong>
                                </div>	
                                <div class="p-col-6">
                                    {{$t('Deudas')}}:
                                </div>	
                                <div class="p-col-6">
                                    <strong>{{moneda(police.poliz.deudas)}}</strong>
                                </div>	
                            </div>
                        </div>
                        <div class="p-col-4">
                            <div class="p-grid">
                                <div class="p-col-6">
                                    {{$t('Premie WA')}}:
                                </div>	
                                <div class="p-col-6">
                                    <strong>{{moneda(police.evaluacion.primabrutat*police.evaluacion.duracion)}}</strong>
                                </div>	
                                <div class="p-col-6">
                                    {{$t('Costo')}}:
                                </div>	
                                <div class="p-col-6">
                                    <strong>{{moneda(police.evaluacion.kosten)}}</strong>
                                </div>	
                                <div class="p-col-6">
                                    {{$t('OB')}}:
                                </div>	
                                <div class="p-col-6">
                                    <strong>{{moneda(police.evaluacion.ob)}}</strong>
                                </div>	
                                <div class="p-col-6">
                                    {{$t('Monto')}}:
                                </div>	                                
                                <div class="p-col-6">
                                    <strong>{{moneda(police.evaluacion.montofinal)}}</strong>
                                </div>	                                
                            </div>
                        </div>
                    </div>
                    <br>
                    <div v-if="police.estado.code==6">
                        <h4>{{$t('Pago Pendiente')}}: <strong>{{moneda(parseFloat(((police.evaluacion.montofinal-police.evaluacion.kosten-police.evaluacion.ob)/police.poliz.dias)*(police.poliz.vend)-police.poliz.deudas))}}</strong></h4>
                    </div>	
                </div>
                <br>
            </div>
            <template #footer>
                {{$t('Estatus')}}:
                <Dropdown v-model="police.estado" :options="estados" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                <Button :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="Guardar" />
                <Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="displayeditar = false"/>
            </template>
        </Dialog>

        <Dialog v-model:visible="displayrenov" :style="{width: size}" :header="$t('Renovación')" :modal="true" :maximizable="true">
            <br>
            <div class="confirmation-content">
                <div class="p-field">
                    <h4>{{$t('Información Básica')}}</h4>
                    <div class="p-grid">
                        <div class="p-col-6">
                            {{$t('Fecha')}}: <strong>{{police.fecha}}</strong>			
                        </div>
                        <div class="p-col-6">
                            {{$t('Vencimiento')}}: <strong>{{police.vence}}</strong>
                        </div>
                        <div class="p-col-12">
                            {{$t('Póliza')+' #'}}: <strong>{{police.codigo}}</strong>			
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <template #footer>
                <Button :label="$t('Renovar')" icon="pi pi-check" class="p-button-secondary" @click="GuardarRen" />
                <Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="displayrenov = false"/>
            </template>
        </Dialog>


        <View :display="displayview" :police="police" :duracion="duracion" :cuotas="cuotas" @aprob-view="onMenuToggle"  @close-view="close"/>
    </div>
</template>

<script>
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";
import View from '../components/View.vue';
import Token from "uuid-token-generator";


export default {
	components: {
        'View': View
    },
	data() {
		return {
			selectedCustomers: null,
			filters: {},
			i18n: null,
            caso: null,
            poliza: [],
			url: null,
			opcion: null,
			opciones: [],
			opcion2: null,
			opciones2: [{code: 0,   nombre: 'Todos', name: 'All'}],
            polizas: [],
            displayview: false,
            police: {},
            duracion: [],
            estad: [],
            estados: [],
            cuotas: [],
            displayeditar: false,
            displayrenov: false,
			pdf: null,
            size: '60vw',
            fecha: null,
            mensaje: null,
        }
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
		const tokgen = new Token(256);
		this.pdf = tokgen.generate();
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
			this.$store.commit('Loading');
            const Consulta = new API('Polizas');
            Consulta.Ini('GestionBroker').then(result => {
                //this.$store.state.error = result;
                if (result.consult.opciones){
                    this.opciones = result.consult.opciones;
                }
                if (result.consult.estados){
                    result.consult.estados.forEach(result => {
                        this.opciones2.push(result);
                    });
                }
                this.duracion = result.consult.duracion;
                this.cuotas = result.consult.cuotas;
                this.mensaje = result.consult.mensaje;
                this.$store.commit('Loading');
            }); 
        },
        VerPDF(data){
            window.open(url.api+'firmado/'+data, '_blank');
        },
		myUploader(data) {
			this.$store.commit('Loading');
			const Consulta = new API('Polizas');
			Consulta.Procesar('GestionBroker',{
				caso: 	'RevPDF',
				pdf: this.pdf,
				data: data,
                leng: this.i18n.locale(),
			}).then(response => {
				//this.$store.state.error = response;	
				if(response.result){
					data.firmado =  response.result;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('No fue procesada la solicitud'), 
						life: 3000
					});
				}
				const tokgen = new Token(256);
				this.pdf = tokgen.generate();
				this.$store.commit('Loading');
			});
		},
        Renovacion(data) {
			this.$store.commit('Loading');
            this.police = data;
            this.displayrenov = true;
            this.$store.commit('Loading');
        },
        Editar(data) {
			this.$store.commit('Loading');
            this.police = {};
            this.$store.commit('Mobile');
            const Consulta = new API('Polizas');
			Consulta.Procesar('GestionBroker',{
				caso: 	'Ver',
                poliza: data.id,
				tipo: data.tipo,
				}).then(response => {
					//this.$store.state.error =  response;
                    this.police = response.result.poliza;
                    this.police.vence2 = Consulta.fstring(this.police.vence);
                    this.estados = response.result.estados;
                    this.estados.forEach(element => {
                        if(this.police.estado==element.code){
                            this.police.estado = element;
                        }
                    });
                    this.$store.commit('Loading');
                    this.displayeditar = true;
                });           
        },
        Guardar() {
			this.$store.commit('Loading');
            this.$store.commit('Mobile');
            const Consulta = new API('Polizas');
            var vence = Consulta.fdate(this.police.vence2);
			Consulta.Procesar('GestionBroker',{
				caso: 	'UPD',
                poliza: this.police,
                vence: vence,
				}).then(response => {
					//this.$store.state.error =  response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
                        this.displayeditar = false;
						this.$store.commit('Loading');
						this.Buscar2();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
                        this.displayeditar = false;
						this.$store.commit('Loading');
					}
                });           
        },
        GuardarRen() {
			this.$store.commit('Loading');
            this.$store.commit('Mobile');
            const Consulta = new API('Polizas');
            var vence = Consulta.fdate(this.police.vence2);
			Consulta.Procesar('GestionBroker',{
				caso: 	'RENOVAR',
                poliza: this.police,
                vence: vence,
				}).then(response => {
					//this.$store.state.error =  response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
                        this.displayrenov = false;
						this.$store.commit('Loading');
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
                        this.displayrenov = false;
						this.$store.commit('Loading');
					}
                });           
        },
        Buscar(){
            this.opcion2 = null;


        },
        Buscar2(){
			this.$store.commit('Loading');
			this.poliza = [];
            const Consulta = new API('Polizas');
			Consulta.Procesar('GestionBroker',{
				caso: 	'Buscar',
				opcion: this.opcion.code,
				opcion2: this.opcion2.code,
				}).then(response => {
					//this.$store.state.error = response.result.poliza[0];	
                    if (response.result.poliza){
                        this.poliza = response.result.poliza;
                        this.poliza.vence = Consulta.fdate(this.poliza.vence);

                    }
                    this.$store.commit('Loading');
                });
        },
        Ver(data){
            this.$store.commit('Loading');
            var mensaje = null;
            if(this.i18n.locale() == 'en'){mensaje = this.mensaje.en_text;}
            if(this.i18n.locale() == 'es'){mensaje = this.mensaje.es_text;}
            const Consulta = new API('Polizas');
			Consulta.Procesar('GestionBroker',{
				caso: 	'Detalles',
                poliza: data,
				}).then(response => {
					//this.$store.state.error =  response;
                    if(data.tipo == 1){data.incendio = response.result.detalle;}
                    if(data.tipo == 2){data.vehiculo = response.result.detalle;}
                    if(data.tipo == 3){data.salud = response.result.detalle;}
                    if(data.tipo == 4){data.proyecto = response.result.detalle;}
                     const doc = new PDF();
                    doc.Certificado(data,mensaje);
                    this.$store.commit('Loading');
                });           
        },
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
        openview(data) {
			this.$store.commit('Loading');
            this.police = {};
            this.$store.commit('Mobile');
            const Consulta = new API('Polizas');
			Consulta.Procesar('GestionBroker',{
				caso: 	'Ver',
                poliza: data.id,
				tipo: data.tipo,
				}).then(response => {
					//this.$store.state.error =  response;
                    this.police = response.result.poliza;
                    this.duracion = response.result.duracion;
                    this.cuotas = response.result.cuotas;
                    this.$store.commit('Loading');
                    this.displayview = true;
                });           
        },
        close() {
            this.displayview = false;
        },
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},

	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
